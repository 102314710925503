
import { apiEngineList, apiEngineField, apiAccountAdd, apiAccountEdit, apiEngineDetail } from '@/api/setting'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PageMode } from "@/utils/type";

@Component
export default class LogisticsEdit extends Vue {
    form: any = {
        id: 0, title: '', remark: '',customer_id:'',api_token:'', data: {}
    }
    formData: any = [
       
    ]
    engineList: any = []
    type = ''
    mode = 'add'

    handleSave() {
        this.form.type = this.type
        this.form.data = {}
        for (let i of this.formData) {
            this.form.data[i['field']] = this.form[i['field']]
        }
        let params: any = {}
        if (this.mode == 'edit') {
            params.id = this.form.id
            params.title = this.form.title
            params.data = this.form.data
            params.remark = this.form.remark
            apiAccountEdit(params).then(res => {
                this.$message.success('编辑成功')
            })
        } else {
            params.title = this.form.title
            params.data = this.form.data
            params.remark = this.form.remark
            apiAccountAdd(this.form).then(res => {
                this.$message.success('新增成功')
            })
        }
        // apiSetLogisticsConfig(this.form)
    }

    getEngineList() {
        apiEngineList({}).then(res => {
            let keys = Object.keys(res)
            for (let i of keys) {
                this.engineList.push({ value: i, label: res[i] })
            }
        })
    }

    changeType() {
        this.formData = []
        apiEngineField({ type: this.type }).then(res => {
            let keys = Object.keys(res)
            this.$nextTick(() => {
                for (let i of keys) {
                    this.formData.push({ label: res[i], field: i })
                }
            })
        })
    }

    created() {
        const query: any = this.$route.query;
        if (query.mode) this.mode = query.mode;

        if (this.mode === PageMode.EDIT) {
            this.form.id = query.id * 1;
            apiEngineDetail({ id: this.form.id }).then(res => {
                this.form['id'] = res.id
                this.form['title'] = res.title
                this.form['remark'] = res.remark
                this.type = res.type
                let keys = Object.keys(res.data_field)
                for (let i of keys) {
                    this.formData.push({ label: res.data_field[i], field: i })
                    this.form[i] = res.data[i]
                }
            })
        }
        this.getEngineList()
    }
}
